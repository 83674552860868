<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1157_39124" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1157_39124)">
      <path d="M5.94926 11.9009L4.375 14.0001C4.16792 14.2763 3.77623 14.3321 3.5 14.125C3.22382 13.9179 3.16802 13.5262 3.3751 13.25L4.88396 11.238C3.98676 10.634 3.09151 9.91531 2.19866 9.08194C1.94624 8.8463 1.9325 8.45086 2.16814 8.19846C2.40356 7.94626 2.79922 7.93253 3.05139 8.16817C9.0614 13.7775 14.9386 13.7775 20.9486 8.16817C21.2008 7.93253 21.5964 7.94626 21.8319 8.19846C22.0675 8.45089 22.0538 8.84628 21.8013 9.08194C20.9085 9.91531 20.0132 10.6339 19.116 11.238L20.6249 13.25C20.832 13.5262 20.7762 13.9179 20.5 14.125C20.2238 14.3321 19.8321 14.2763 19.625 14.0001L18.0507 11.9009C17.3671 12.2916 16.6827 12.6157 15.9976 12.8736L16.9554 15.2679C17.0835 15.5883 16.9275 15.9521 16.607 16.0803C16.2866 16.2085 15.9228 16.0526 15.7946 15.7322L14.8041 13.2555C14.078 13.4469 13.3515 13.5641 12.6249 13.6067V16.125C12.6249 16.4703 12.3452 16.7499 12 16.7499C11.6547 16.7499 11.375 16.4703 11.375 16.125V13.6067C10.6485 13.5642 9.92198 13.4469 9.19587 13.2555L8.20536 15.7322C8.07718 16.0526 7.71337 16.2085 7.39292 16.0803C7.07248 15.9521 6.91641 15.5883 7.04458 15.2679L8.00239 12.8736C7.31729 12.6157 6.63281 12.2916 5.94922 11.9009H5.94926Z" fill="#BBBBBB"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPassword"
}
</script>